<template>
  <g :fill="color" fill-rule="evenodd">
    <g fill="#E5472D">
      <g>
        <g>
          <path
            d="M13.22 1.715l-1.863 2-1.863-2c-2.02-2.17-5.42-2.297-7.597-.285-.099.092-.193.186-.285.285-2.15 2.291-2.15 5.851 0 8.142L11.5 20l9.888-10.143c2.15-2.291 2.15-5.851 0-8.142-2.203-2.249-5.818-2.292-8.074-.095l-.094.095z"
            transform="translate(-35.000000, -459.000000) translate(20.000000, 449.000000) translate(15.000000, 10.000000)"
          />
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
